export function viewBoxTransformer(
  width: number,
  height: number,
): {
  viewBoxWidth: number
  viewBoxHeight: number
  newWidth: number
  newHeight: number
} {
  let ratio
  const bodyWidth = document.body.offsetWidth
  const maxWH = bodyWidth / 2
  if (width < maxWH) {
    return {
      viewBoxWidth: width,
      viewBoxHeight: height,
      newWidth: width,
      newHeight: height,
    }
  }

  const newWidth = maxWH
  ratio = newWidth / width
  return {
    viewBoxWidth: width,
    viewBoxHeight: height,
    newWidth,
    newHeight: height * ratio,
  }
}
