import './Toolbar.css'
import { copyAsPNG, downloadPNG, shareDiagram } from './toolbar-functions'
import { ReactComponent as CopySvg } from '../assets/icons/squares.svg'
import { ReactComponent as ChecmarkSvg } from '../assets/icons/checkmark.svg'
import { ReactComponent as DownloadSvg } from '../assets/icons/download.svg'
import { ReactComponent as QuestionSvg } from '../assets/icons/question.svg'
import { ReactComponent as InfoSvg } from '../assets/icons/info.svg'
import { ReactComponent as ShareSvg } from '../assets/icons/right-arrow.svg'
import { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { getSvgElement } from '../sequence-diagram/diagram-data'

export interface Props {
  diagramText: string
}

export const Toolbar = (props: Props) => {
  const [diagramIsRendered, setDiagramIsRendered] = useState(false)
  const [svgCopied, setSvgCopied] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setSvgCopied(false)
    }, 1e3)
  }, [svgCopied])
  const [linkCopied, setLinkCopied] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setLinkCopied(false)
    }, 1e3)
  }, [linkCopied])
  const clipboardItemApiSupported = typeof ClipboardItem !== 'undefined'
  const isAtlassianMacro =
    process.env.REACT_APP_BUILD_TARGET === 'atlassian-macro'

  useEffect(() => {
    setTimeout(() => {
      // check if diagram is rendered 600ms later because of the debounce
      if (getSvgElement()) {
        setDiagramIsRendered(true)
      }
    }, 600)
  }, [props.diagramText])

  return (
    <div className="toolbar">
      {clipboardItemApiSupported && !isAtlassianMacro && diagramIsRendered && (
        <div className="buttonWrapper">
          <button
            className="button"
            onClick={async () => {
              if (await copyAsPNG()) {
                setSvgCopied(true)
              }
              gaEvent('copyAsPNG')
            }}
            title="Copy PNG"
          >
            <CopySvg />
          </button>
          <div className="onclick" style={{ opacity: svgCopied ? 1 : 0 }}>
            <ChecmarkSvg /> <div>Copied</div>
          </div>
        </div>
      )}

      {diagramIsRendered && (
        <button
          className="button"
          onClick={() => {
            if (downloadPNG()) {
              gaEvent('downloadPNG')
            }
          }}
          title="Download PNG"
        >
          <DownloadSvg />
        </button>
      )}

      {!isAtlassianMacro && props.diagramText && (
        <div className="buttonWrapper">
          <button
            className="button"
            onClick={() => {
              shareDiagram(props.diagramText)
              setLinkCopied(true)
              gaEvent('shareDiagram')
            }}
            title="Generate shareable link"
          >
            <ShareSvg />
          </button>
          <div className="onclick" style={{ opacity: linkCopied ? 1 : 0 }}>
            <ChecmarkSvg /> <div>Copied</div>
          </div>
        </div>
      )}

      {((!isAtlassianMacro && props.diagramText) || diagramIsRendered) && (
        <div className="splitter"></div>
      )}

      <a
        title="Documentation"
        className="button"
        href="https://www.colibridiagrams.net/#diagram-syntax"
        target="_blank"
        rel="noreferrer"
      >
        <InfoSvg />
      </a>
      <a
        title="Questions & Bugs"
        className="button"
        href="https://github.com/ColibriDiagrams/sequence-diagrams-issues"
        target="_blank"
        rel="noreferrer"
      >
        <QuestionSvg />
      </a>
    </div>
  )
}

function gaEvent(name: string) {
  ReactGA.event({
    category: 'Toolbar',
    action: name,
  })
}
