import { Size } from '@colibridiagrams/lib-sequence-diagram'
import { Buffer } from 'buffer'
import { getSvgElement } from './diagram-data'

const cache: { [url: string]: string } = {}
const fonts = [
  {
    url: 'https://fonts.cdnfonts.com/s/15889/FiraSans-Regular.woff',
    weight: 400,
  },
  {
    url: 'https://fonts.cdnfonts.com/s/15889/FiraSans-Bold.woff',
    weight: 700,
  },
]

export async function getDataURL(size: Size): Promise<string> {
  try {
    const canvas = await svgToCanvas(size)
    return canvas.toDataURL('image/png')
  } catch (e) {
    throw new Error(`Could not convert diagram to image URL: ${e}`)
  }
}

const svgToCanvas = async (size: Size): Promise<HTMLCanvasElement> => {
  const origSvg = getSvgElement()
  if (!origSvg) {
    throw new Error('Could not find SVG element')
  }

  const styleContent = await downloadFonts()

  // clone svg so we can resize it
  const copiedDiagram = document.createElement('div')
  copiedDiagram.appendChild(origSvg.cloneNode(true))
  const svg = copiedDiagram.querySelector('svg')
  if (!svg) {
    throw new Error('Could not find SVG element')
  }

  // add in-line fonts
  const defs = svg.querySelector('defs')
  if (!defs) {
    throw new Error('Could not find SVG defs element')
  }

  const style = document.createElement('style')
  style.innerHTML = styleContent
  defs.appendChild(style)

  // width and height we multiply by 2 to get a better resolution for the image
  svg.setAttribute('width', (size.width * 2).toString())
  svg.setAttribute('height', (size.height * 2).toString())
  svg.setAttribute('viewBox', `0 0 ${size.width} ${size.height}`)

  const svgData = new XMLSerializer().serializeToString(svg)
  const svgURL = `data:image/svg+xml;base64,${Buffer.from(svgData).toString(
    'base64',
  )}`

  return new Promise<HTMLCanvasElement>((resolve, reject) => {
    const svgImg = document.createElement('img')
    svgImg.src = svgURL
    svgImg.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = svgImg.width
      canvas.height = svgImg.height

      canvas.getContext('2d')?.drawImage(svgImg, 0, 0)

      resolve(canvas)
    }
    svgImg.onerror = reject
  })
}

const downloadFonts = async (): Promise<string> => {
  const styleContent: string[] = []
  for (const font of fonts) {
    let fontFace = [
      `@font-face {`,
      `  font-family: 'Fira Sans';`,
      `  font-style: normal;`,
      `  font-weight: ${font.weight};`,
    ]
    if (!cache[font.url]) {
      let blob: Blob
      try {
        const resp = await fetch(font.url)
        blob = await resp.blob()
      } catch (e) {
        throw new Error(`Error loading font ${font.url}: ${e}`)
      }
      const fontSrc = await new Promise<string>((resolve, reject) => {
        const f = new FileReader()
        f.addEventListener('load', () =>
          f.result
            ? resolve(
                typeof f.result === 'string'
                  ? f.result
                  : Buffer.from(f.result).toString('base64'),
              )
            : reject(`Error loading font ${font.url}: no result`),
        )
        f.readAsDataURL(blob)
      })
      cache[font.url] = fontSrc
    }
    fontFace = fontFace.concat([
      `  src: url(${cache[font.url]}) format('woff');`,
      `}`,
      '',
    ])
    styleContent.push(fontFace.join('\n'))
  }
  return styleContent.join('\n')
}
