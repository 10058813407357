import { SequenceDiagram } from '@colibridiagrams/lib-sequence-diagram'
import { DiagramData } from './types'
import { v1 as uuidv1 } from 'uuid'

// TODO: find a better way
const diagramSelector = '#diagram svg'

const getImageStorageKey = (): string => {
  const key = document.body.getAttribute('data-image-storage-key')
  if (!key) {
    throw new Error('Could not find image storage key')
  }
  return key
}
export const setImageStorageKey = (): void => {
  // use random uuid to avoid collisions on page reload
  const imageStorageKey = `diagram-${uuidv1()}}`
  document.body.setAttribute('data-image-storage-key', imageStorageKey)
}

export const getSvgElement = (): Element | null => {
  return document.querySelector(diagramSelector)
}

export const extractDiagramData = (): DiagramData | undefined => {
  const diagramData = sessionStorage.getItem(getImageStorageKey())
  if (!diagramData) {
    return
  }
  return JSON.parse(diagramData) as DiagramData
}

export const extractSerializedDiagram = (): string | undefined => {
  const svgEl = document.querySelector(diagramSelector)
  if (!svgEl) {
    return
  }
  const s = new XMLSerializer()
  return s.serializeToString(svgEl)
}

export const writeDiagramData = (
  sd: SequenceDiagram,
  base64Image: string,
): void => {
  const diagramData: DiagramData = {
    interfaceVersion: 1,
    text: sd.input,
    fonts: sd.getFonts(),
    size: sd.getOriginalSize(),
    image: base64Image,
  }
  sessionStorage.setItem(getImageStorageKey(), JSON.stringify(diagramData))
}
