import { DiagramData } from '../sequence-diagram/types'

const localStorageKey = 'last-diagram'

export const loadDiagram = (): DiagramData | null => {
  const diagram = localStorage.getItem(localStorageKey)
  return diagram ? (JSON.parse(diagram) as DiagramData) : null
}

export const saveDiagram = (diagram: any) => {
  localStorage.setItem(localStorageKey, JSON.stringify(diagram))
}
