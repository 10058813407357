import './Editor.css'
import { EditorView } from '@codemirror/view'
import { Diagnostic, linter } from '@codemirror/lint'
import {
  ParseError,
  SequenceDiagram,
} from '@colibridiagrams/lib-sequence-diagram'

const lintErrors = (view: EditorView): Diagnostic[] => {
  const lines = view.state.doc.toJSON()
  const input = lines.join('\n')
  const diagnostics: Diagnostic[] = []
  const sd = new SequenceDiagram(input)
  try {
    sd.parse()
  } catch (e: any) {
    if (e.hash) {
      e as ParseError
      let from = 0
      let to = 0
      let lineNum = 1
      for (let line of lines) {
        if (e.hash.loc.first_line === lineNum) {
          from += e.hash.loc.first_column
        } else if (e.hash.loc.first_line > lineNum) {
          from += line.length + 1 /* +1 is because of \n */
        }
        if (e.hash.loc.last_line === lineNum) {
          to += e.hash.loc.last_column
          break
        } else if (e.hash.loc.last_line > lineNum) {
          to += line.length + 1
        }
        lineNum++
      }

      diagnostics.push({
        from,
        to,
        severity: 'error',
        message: e.message,
      })
    } else {
      diagnostics.push({
        from: 0,
        to: lines[0].length,
        severity: 'error',
        message: `${e}`,
      })
    }
  }

  return diagnostics
}

export const linterExtension = linter(lintErrors, { delay: 300 })
