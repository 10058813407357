export const dummyDiagramText =
  process.env.REACT_APP_NODE_ENV === 'production'
    ? `title Sample Diagram
Alice->Bob: Hello Bob! \\n How are you?
Bob->Alice: I am good thanks!
`
    : `title Sequence Diagram Sample

participant Browser
participant Server

Browser->Server: Requests secure connection
Server->Browser: SSL certificate \\n (includes public key)
note right of Browser: 1. Validates certificate against known CAs \\n 2. Generates symmetric session key.\\n 3. Encrypts they key using certificate public key
Browser->Server: Sends encrypted session key
note left of Server: Decrypts session key \\n using certificate private key

loop Request/Response
  Browser->Server: Sends data encrypted with session key
  note left of Server: Decrypts data \\n using session key
  Server->Browser: Sends response encrypted with session key
end
`
