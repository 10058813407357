import React, { useEffect, useState, useCallback } from 'react'
import './App.css'
import { dummyDiagramText } from './dummy-diagram-text'
import { SequenceDiagramReact } from './sequence-diagram/SequenceDiagram'
import { useWindowSize } from './hooks/use-window-size'
import Editor from './editor/Editor'
import { Toolbar } from './toolbar/Toolbar'
import { importDiagram } from './toolbar/toolbar-functions'
import { loadDiagram, saveDiagram } from './storage/browser'
import { getMacroData } from './storage/atlassian-macro'
import debounce from 'lodash.debounce'

function App() {
  const [diagramText, setDiagramText] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [activeLicense, setActiveLicense] = useState(false)
  const { height, width } = useWindowSize()
  const minEditorWidth = 400
  const editorToBrowserWidthRatio = 0.4
  const diagramRenderDebounce = 500

  /**
   * On diagram text change
   * With 500ms debounce
   */
  const setDiagramTextDebounced = (value: string) => {
    setDiagramText(value)
    saveDiagram({ interfaceVersion: 1, text: value })
  }
  const onChange = useCallback(
    debounce(setDiagramTextDebounced, diagramRenderDebounce),
    [],
  )

  /**
   * Check macro license
   */
  useEffect(() => {
    if (
      process.env.REACT_APP_NODE_ENV !== 'production' ||
      process.env.REACT_APP_LICENSE_CHECK === 'disabled'
    ) {
      setActiveLicense(true)
      return
    }

    const params = new URLSearchParams(window.location.search)
    setActiveLicense(params.get('lic') === 'active')
  }, [setActiveLicense])

  /**
   * Get saved macro state
   */
  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TARGET !== 'atlassian-macro') {
      // if no AP.events, then we're running this locally
      const importedDiagramText = importDiagram(window.location.href)
      const storedDiagram = loadDiagram()
      setDiagramText(
        importedDiagramText || storedDiagram?.text || dummyDiagramText,
      )
      setLoaded(true)
      return
    }

    getMacroData().then((data) => {
      if (data) {
        setDiagramText(data.text)
      }
      setLoaded(true)
    })
    document.body.classList.add('atlassian-macro')
    // reset styles forced by Atlassian
    document.body.setAttribute('style', 'margin: 0 !important')
  }, [])

  return !activeLicense ? (
    <div className="no-license">
      Colibri Diagrams license has expired. Submitting updates is disabled.
    </div>
  ) : loaded ? (
    <div id="editor-wrapper" className="wrapper">
      <Editor
        diagramText={diagramText}
        height={height ? `${height}px` : undefined}
        width={
          width && width * editorToBrowserWidthRatio > minEditorWidth
            ? `${width * editorToBrowserWidthRatio}px`
            : `${minEditorWidth}px`
        }
        onChange={onChange}
      />
      <Toolbar diagramText={diagramText} />
      {diagramText && (
        <SequenceDiagramReact
          id="diagram"
          height={height}
          diagramText={diagramText}
        />
      )}
    </div>
  ) : (
    <div className="loading">Loading...</div>
  )
}

export default App
