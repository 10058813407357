import React, { createRef } from 'react'
import './Editor.css'
import CodeMirror, { ReactCodeMirrorRef } from '@uiw/react-codemirror'
import { materialLight } from '@uiw/codemirror-theme-material'
import { ViewUpdate } from '@codemirror/view'
import { linterExtension } from './linter-extension'

interface Props {
  diagramText: string
  height?: string
  width?: string
  onChange?(value: string, viewUpdate: ViewUpdate): void
}

/**
 * TODO: enable spellcheck https://github.com/codemirror/dev/issues/63#issuecomment-468644628
 */
function Editor(props: Props) {
  const { diagramText, height, width, onChange } = props
  const editorRef = createRef<ReactCodeMirrorRef>()

  return (
    <div className="code-editor">
      <CodeMirror
        ref={editorRef}
        value={diagramText}
        height={height}
        width={width}
        autoFocus={true}
        placeholder="Enter diagram text."
        theme={materialLight}
        extensions={[linterExtension]}
        onChange={onChange}
      />
    </div>
  )
}

export default Editor
