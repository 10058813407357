import LZString from 'lz-string'
import { extractDiagramData } from '../sequence-diagram/diagram-data'

let baseUrl = 'http://localhost:3001/sequence/'
if (process.env.NODE_ENV === 'production') {
  baseUrl = 'https://app.colibridiagrams.net/sequence/'
}

/**
 * This function takes a diagram text as an input,
 * compresses it, base64 encodes, and copies
 * a URL with the encoded diagram text to clipboard.
 */
export function shareDiagram(diagramText: string) {
  const compressed = LZString.compressToEncodedURIComponent(diagramText)
  const url = `${baseUrl}#diagram=${compressed}`
  navigator.clipboard.writeText(url)
}

export function importDiagram(url: string): string | null {
  // take diagram value from hash params of url
  const diagram = new URL(url).hash.split('diagram=')[1]

  if (diagram) {
    try {
      const diagramText = LZString.decompressFromEncodedURIComponent(diagram)
      // clear the hash part of the url
      window.history.replaceState({}, '', url.split('#')[0])
      return diagramText
    } catch (e) {
      console.error(`Error decompressing diagram: ${e}`)
    }
  }
  return null
}

export async function copyAsPNG(): Promise<boolean> {
  const data = extractDiagramData()
  if (!data) {
    return false
  }

  const blob = await base64ToBlob(data.image.split('base64,')[1], 'image/png')
  const item = new ClipboardItem({ [blob.type]: blob })
  navigator.clipboard.write([item])
  return true
}

export function downloadPNG(): boolean {
  const data = extractDiagramData()
  if (!data) {
    return false
  }

  triggerDownload(data.image)
  return true
}

const triggerDownload = (image: string) => {
  const fileName = 'colibridiagrams'
  const evt = new MouseEvent('click', {
    view: window,
    bubbles: false,
    cancelable: true,
  })

  const a = document.createElement('a')
  a.setAttribute('download', `${fileName}.png`)
  a.setAttribute('href', image)
  a.setAttribute('target', '_blank')

  a.dispatchEvent(evt)
}

/**
 * Converts base64 string to blob
 */
const base64ToBlob = (
  base64: string,
  type = 'application/octet-stream',
): Promise<Blob> =>
  fetch(`data:${type};base64,${base64}`).then((res) => res.blob())
