import {
  extractDiagramData,
  extractSerializedDiagram,
} from '../sequence-diagram/diagram-data'
import { DiagramData } from '../sequence-diagram/types'

export function initAtlassianMacro() {
  if (!AP.events) {
    console.error('Atlassian Pass not loaded')
    return
  }

  // this method is deprecated, but the new method doesn't work:
  // https://community.developer.atlassian.com/t/ap-dialog-submit-and-cancel-events/7412/5
  ;(AP.dialog.getButton('submit') as any).bind(() => {
    const diagramData = extractDiagramData()
    if (!diagramData) {
      console.log('No diagram data found')
      return false
    }

    const diagramSvg = extractSerializedDiagram()
    console.log('Saving diagram data', diagramData)
    // save params as string because of this bug:
    // https://community.developer.atlassian.com/t/ap-confluence-savemacro-generates-error-if-macroparameters-has-nested-array/47745
    AP.confluence.saveMacro(
      { diagramData: JSON.stringify(diagramData) },
      diagramSvg,
    )
    return true
  })
}

export async function loadAtlassianPass(): Promise<void> {
  return new Promise((resolve) => {
    const apScript = document.createElement('script')
    apScript.setAttribute('src', 'https://connect-cdn.atl-paas.net/all.js')
    apScript.async = false
    document.body.appendChild(apScript)
    apScript.onload = () => resolve()
    apScript.onerror = (e) => console.error('Error loading Atlassian Pass', e)
  })
}

export async function getMacroData(): Promise<DiagramData | undefined> {
  return new Promise<DiagramData | undefined>((resolve) => {
    AP.confluence.getMacroData((data: any) => {
      if (!data?.diagramData) {
        resolve(undefined)
        return
      }
      const macroData = JSON.parse(data.diagramData) as DiagramData
      resolve(macroData)
    })
  })
}
