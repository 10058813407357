import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import ReactGA from 'react-ga4'
import {
  initAtlassianMacro,
  loadAtlassianPass,
} from './storage/atlassian-macro'
import { setImageStorageKey } from './sequence-diagram/diagram-data'
// import reportWebVitals from './reportWebVitals';

ReactGA.initialize([
  {
    trackingId: 'G-21NHWG9D0V',
    gaOptions: {
      anonymizeIp: true,
      testMode: process.env.REACT_APP_NODE_ENV !== 'production',
    },
  },
])

setImageStorageKey()

function renderReactRoot() {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
  )
  root.render(<App />)
}

if (process.env.REACT_APP_BUILD_TARGET === 'atlassian-macro') {
  loadAtlassianPass().then(() => {
    initAtlassianMacro()
    renderReactRoot()
  })
} else {
  if (window.location.pathname === '/') {
    window.location.assign('/sequence/')
  }
  renderReactRoot()
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
