import React, { createRef, useEffect } from 'react'
import { SequenceDiagram } from '@colibridiagrams/lib-sequence-diagram'
import { viewBoxTransformer } from './view-box-transformer'
import { writeDiagramData } from './diagram-data'
import { getDataURL } from './svg-to-png'

interface Props {
  id: string
  diagramText: string
  /** Container height in px */
  height?: number
}

export const SequenceDiagramReact = (props: Props) => {
  const { id, diagramText, height } = props
  const diagramRef = createRef<HTMLDivElement>()

  useEffect(() => {
    if (!diagramText) {
      return
    }

    const container = document.getElementById(id)
    if (!container) {
      console.log('no container')
      return
    }

    try {
      const sd = new SequenceDiagram(diagramText, { viewBoxTransformer })
      sd.parse()
        .drawSVG(container)
        .then(async () => {
          try {
            writeDiagramData(sd, await getDataURL(sd.getOriginalSize()))
          } catch (e) {
            console.error(`Error while writing diagram state: ${e}`)
          }
        })
        .catch(console.error)
    } catch (e) {
      console.error(e)
    }
  }, [id, diagramText, diagramRef])

  return diagramText ? (
    <div
      id={id}
      ref={diagramRef}
      style={{ height }}
      className="diagram-container"
    />
  ) : null
}
